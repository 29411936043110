import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Fixed } from "@components/feature-list";
import StartButton from "@components/StartButton";

const EftposCards = ({ getStarted, reverse }) => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "digital-card" }) {
        sharp: childImageSharp {
          fixed(width: 438) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "digital-card" }) {
        sharp: childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "digital-card" }) {
        sharp: childImageSharp {
          fixed(width: 560) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.xs.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px AND max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Fixed
      id="physical-eftpos"
      description={<Description getStarted={!!getStarted}/>}
      image={sources}
      reverse={reverse} />
  );
}

const Description = ({ getStarted }) => (
  <div>
    <div>
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          GIFTING | LOYALTY
        </span>

        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Digital Gift Card
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        Your brand is powerful and what better way to showcase your brand than having it in
        a digital wallet? Get ahead of your competitors by rewarding your staff, clients
        or customers with a branded app and branded card in the Apple or Google digital wallet.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        Beautifully designed cards with your chosen artwork, WAIVPAY’s digital Mastercard
        boosts brand awareness, cements loyalty and increases sales. Each card can store
        value from $5 to $1,000.
      </p>

      <p className="mt-4 text-lg text-gray-500">
        Pair with <Link to='/#qube' className="text-highlight">WAIVPAY Qube</Link> for deep insights into customer behaviours and traffic analytics.
      </p>
      {
        getStarted &&
          <div className="mt-6">
            <StartButton to="/giftcards" />
          </div>
      }
    </div>
  </div>
);

export default EftposCards;

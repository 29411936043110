import React from "react";
import SEO from "@components/seo";
import Layout from "@components/layout";
import Footer from "@components/footers/dark";
import MenuBar from "@components/menuBar/MenuBar";
import FeatureList from "@components/feature-list";
import Hero from "./Hero.js";
import Cta from "@components/Cta";
import Qube from "@components/qube/qube.js";

import CardApp from "./features/CardApp.js";
import GiftingSolutions from "./features/GiftingSolutions.js";
import DigitalCardAndDigitalApp from "./features/DigitalCardAndDigitalApp.js";
import PhysicalGiftCards from "./features/PhysicalGiftCards.js";
import Webstores from "./features/Webstores.js";
import Concierge from "./features/Concierge.js";
import DigitalGiftKiosk from "./features/DigitalGiftKiosk.js";
import MyReceiptRewards from "./features/MyReceiptRewards.js";
import ShoppingCentreGiftingSolution from "./features/GiftingSolutionsInfo.js";
import LogoCarousel from "./features/LogoCarousel.js";

export default function ShoppingCentres() {
  return (
    <>
      <SEO title="Team" />
      <MenuBar/>
      <Layout>
        <Hero/>
        <ShoppingCentreGiftingSolution/>
        <CardApp/>
        <LogoCarousel/> 
        <FeatureList alternate>
          <GiftingSolutions/>
          <DigitalCardAndDigitalApp/>
          <PhysicalGiftCards/>
          <Webstores/>
          <Concierge/>
          <DigitalGiftKiosk/>
          <MyReceiptRewards/>
        </FeatureList>
        <Qube/>
        <Cta>
          <span>
            WAIVPAY&rsquo;s digital, physical and cashback payments technology
            for instant rewarding, recognition and incentives.
          </span>
        </Cta>
      </Layout>
      <Footer/>
    </>
  );
}
